// @flow

import React, { useRef, useEffect } from 'react'
import { useKnowledgeItems, useKnowledgeWebpageForm } from '../../../../../../../../hooks'

import {
  CCButton,
  CCLabel,
  CCTextInput,
  showErrorMessages,
  showSpinnerMessage,
  TrboIcon
} from '../../../../../../../UI'

import { DataSourceSettingsTemplate } from '../../../DataSourceSettingsTemplate'

import './WebpageForm.scss'

export function WebpageForm () {
  const {
    setIsValidDataSourceModal,
    setDataSourceOptionModalType,
    closeModal,
    setUploadInProgress
  } = useKnowledgeItems()
  const {
    webpages,
    isValidModal,
    handleChangeURL,
    addNewWebpage,
    clearWebpages,
    handleDelete,
    handlePaste,
    handleSave,
    loadKnowledgeWebsitePages
  } = useKnowledgeWebpageForm()

  const inputContainerRef = useRef(null)

  useEffect(() => {
    if (inputContainerRef.current) {
      inputContainerRef.current.scrollTop = inputContainerRef.current.scrollHeight
    }
  }, [webpages])

  function handleKeyPress (e) {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault()
      addNewWebpage()
    }
  }

  async function onSaveClick () {
    try {
      await handleSave()
      setUploadInProgress()
      closeModal()
      showSpinnerMessage('Data added and training is in progress. You will receive an email with a confirmation message.', 'Training in progress')
      await loadKnowledgeWebsitePages()
    } catch (error) {
      showErrorMessages(error)
    }
  }

  function onBackClick () {
    clearWebpages()
    setIsValidDataSourceModal(false)
    setDataSourceOptionModalType(null)
  }

  const validWebpages = webpages.filter(page => page.isValid)

  return (
    <DataSourceSettingsTemplate
      buttonSaveLabel="Add source"
      isSaveButtonDisabled={!isValidModal}
      onBackClick={onBackClick}
      onSaveClick={onSaveClick}
      hideBackButton
    >
      <div className="kb-webpage">
        <div className="kb-webpage__add-line">
          <div className="kb-webpage__add-line__text">
            <span className="kb-webpage__add-line__text__heading">Web Pages</span>
            <TrboIcon
              name="info-circle"
              tooltip="Tip: press &apos;Shift + Enter&apos; to add a new page. You can also paste a list of URLs from clipboard directly into the input field."
              tooltipPlacement="right"
            />
            {validWebpages.length > 0 &&
              <CCLabel
                text={`${validWebpages.length} ${validWebpages.length === 1 ? 'page' : 'pages'}`}
                color="gray"
              />
            }
          </div>
          <CCButton
            icon="plus"
            iconVersion="6"
            iconWeight="regular"
            onClick={addNewWebpage}
          >
            Add
          </CCButton>
        </div>
        <div ref={inputContainerRef} className="kb-webpage__segment">
          {webpages.map((webpage, index) => (
            <div key={index} className="kb-webpage__content">
              <div className="kb-webpage__content__row">
                <div className="kb-webpage__content__row--input">
                  <CCTextInput
                    customClasses={!webpage.isValid && webpage.url !== '' ? 'input-error' : ''}
                    type="text"
                    placeholder="https://www.example.com"
                    value={webpage.url}
                    onChange={e => handleChangeURL(index, e)}
                    onKeyPress={handleKeyPress}
                    onPaste={e => handlePaste(e, index)}
                  />
                  {!webpage.isValid && webpage.url !== '' &&
                    <TrboIcon name="triangle-exclamation" weight="solid" />
                  }
                </div>
                <CCButton
                  icon="trash"
                  iconVersion="6"
                  iconWeight="regular"
                  onClick={() => handleDelete(index)}
                  disabled={index === 0}
                />
              </div>
              {!webpage.isValid && webpage.url !== '' &&
                <div className="kb-webpage__content__error-message">Please enter a valid URL</div>
              }
            </div>
          ))}
        </div>
      </div>
    </DataSourceSettingsTemplate>
  )
}
