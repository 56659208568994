// @flow

import React from 'react'

import { App } from '../../App'

import { CCNavbar } from './CCNavbar'

export default function CCNavbarApp () {
  return (
    <App>
      <CCNavbar />
    </App>
  )
}
