// @flow

import { Action, action, Thunk, thunk } from 'easy-peasy'
import { validateURL } from '../utils/validateURL'
import { KnowledgeWebsitePagesService } from '../services'
import { KnowledgeWebsitePage } from '../models'

type Webpage = {
  url: string,
  isValid: boolean
}

export type WebpageFormModel = {
  webpages: Array<Webpage>,
  isValidModal: boolean,
  knowledgeWebsitePages: Array<KnowledgeWebsitePage>,
  pages: number,
  page: number,
  search: string | null,
  orderValue: string | null,
  orderDirection: string | null,
  isLoadingList: boolean,
  setIsLoadingList: Action<WebpageFormModel, boolean>,
  setKnowledgeWebsitePages: Action<WebpageFormModel, Array<KnowledgeWebsitePage>>,
  setPages: Action<WebpageFormModel, number>,
  setPage: Action<WebpageFormModel, number>,
  setCount: Action<WebpageFormModel, number>,
  setSearch: Action<WebpageFormModel, string>,
  setOrderValue: Action<WebpageFormModel, string>,
  setOrderDirection: Action<WebpageFormModel, string>,
  setWebpages: Action<WebpageFormModel, Array<Webpage>>,
  setIsValidModal: Action<WebpageFormModel, boolean>,
  addNewWebpage: Action<WebpageFormModel, void>,
  deleteWebpage: Action<WebpageFormModel, number>,
  removeKnowledgeWebsitePages: Action<WebpageFormModel, number>,
  updateWebpage: Action<WebpageFormModel, { index: number, url: string }>,
  validateAndUpdateWebpage: Thunk<WebpageFormModel, { index: number, url: string }>,
  deleteKnowledgeWebsitePage: Thunk<WebpageFormModel, number>
}

export const knowledgeWebpageFormModel: WebpageFormModel = {
  webpages: [{ url: '', isValid: false }],
  isValidModal: false,
  knowledgeWebsitePages: [],
  pages: 0,
  page: 0,
  count: 0,
  search: null,
  orderValue: null,
  orderDirection: null,
  isLoadingList: true,

  setWebpages: action((state, updatedWebpages) => {
    state.webpages = updatedWebpages
  }),

  setIsValidModal: action((state, isValid) => {
    state.isValidModal = isValid
  }),

  addNewWebpage: action((state) => {
    state.webpages.push({ url: '', isValid: false })
  }),

  pushWebpage: action((state, { url, isValid }) => {
    state.webpages.push({ url, isValid })
    state.isValidModal = state.webpages.every(item => item.isValid)
  }),

  deleteWebpage: action((state, index) => {
    state.webpages = state.webpages.filter((_, i) => i !== index)
    state.isValidModal = state.webpages.length > 0 && state.webpages.every(item => item.isValid)
  }),

  removeKnowledgeWebsitePages: action((state, id) => {
    state.knowledgeWebsitePages = state.knowledgeWebsitePages.filter((item) => item.id !== id)
  }),

  updateWebpage: action((state, { index, url }) => {
    state.webpages[index].url = url
    state.webpages[index].isValid = validateURL(url)
    state.isValidModal = state.webpages.every(item => item.isValid)
  }),

  validateAndUpdateWebpage: thunk((actions, { index, url }, { getState }) => {
    const { webpages } = getState()
    const updatedWebpages = [...webpages]
    updatedWebpages[index].url = url
    updatedWebpages[index].isValid = validateURL(url)

    actions.setWebpages(updatedWebpages)

    const allValid = updatedWebpages.every(item => item.isValid)
    actions.setIsValidModal(allValid)
  }),

  setIsLoadingList: action((state, payload) => {
    state.isLoadingList = payload
  }),

  setPages: action((state, payload) => {
    state.pages = payload
  }),

  setPage: action((state, payload) => {
    state.page = payload
  }),

  setCount: action((state, payload) => {
    state.count = payload
  }),

  setSearch: action((state, payload) => {
    state.search = payload
  }),

  setOrderValue: action((state, payload) => {
    state.orderValue = payload
  }),

  setOrderDirection: action((state, payload) => {
    state.orderDirection = payload
  }),

  create: thunk(async (actions, payload) => {
    await KnowledgeWebsitePagesService.create(payload.shop, payload.data)
  }),

  setKnowledgeWebsitePages: action((state, payload) => {
    state.knowledgeWebsitePages = payload
  }),

  fetch: thunk(async (actions, payload) => {
    actions.setIsLoadingList(true)
    const { knowledgeWebsitePages, pages, page, count } = await KnowledgeWebsitePagesService.getList(payload.shop, payload.params)
    actions.setKnowledgeWebsitePages(knowledgeWebsitePages)
    actions.setPages(pages)
    actions.setPage(page)
    actions.setCount(count)
    actions.setIsLoadingList(false)
  }),

  deleteKnowledgeWebsitePage: thunk(async (actions, payload) => {
    const success = await KnowledgeWebsitePagesService.delete(payload.shop, payload.id)
    if (success) {
      actions.removeKnowledgeWebsitePages(payload.id)
    }
  })
}
