// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../models'
import { useModal } from '../../../../../hooks'

import { CCButton, TrboIcon } from '../../../../UI'

import { ViewModal } from './modals/ViewModal'

import './ActionsCell.scss'

type Props = {
  row: {
    original: KnowledgeItem
  }
}

export function AskQuestionActionsCell ({ row }: Props) {
  const [isViewModalOpen, , onViewRequestOpen, onViewRequestClose] = useModal()

  function handleURL () {
    window.open(row.original.link, '_blank')
  }

  return (
    <div className='k-table-additional-settings'>
      {row.original.input_source === 'feed_link' &&
        <CCButton
          tooltip="Open URL"
          onClick={handleURL}
        >
          <TrboIcon name="external-link" />
        </CCButton>
      }
      {row.original.input_source !== 'feed_link' &&
        <CCButton
          onClick={onViewRequestOpen}
          tooltip="View entry"
        >
          <TrboIcon name="eye" />
        </CCButton>
      }
      {isViewModalOpen && (
        <ViewModal
          row={row}
          onViewRequestClose={onViewRequestClose}
        />
      )}
    </div>
  )
}
