// @flow

import React from 'react'

import { CCButton } from '../../../../UI'

type Row = {
  input_source: 'csv' | 'manual' | 'feed_link' | 'website'
}

type Props = {
  row: Row
}

function getText (row: Row): string {
  const values = {
    csv: '.csv file',
    feed_link: 'Feed link',
    website: 'Website',
    manual: 'Manual'
  }

  return values[row.input_source]
}

function getIcon (row: Row): string {
  const values = {
    csv: 'file-csv',
    feed_link: 'link',
    website: 'globe-pointer',
    manual: 'pen-line'
  }

  return values[row.input_source]
}

export function InputSourceCell ({ row }: Props) {
  return (
    <CCButton
      size="xsmall"
      customClasses="input-source-cell-button"
      onClick={() => null}
    >
      <i className={`fa6 fa6-regular fa6-${getIcon(row)}`} />
      {getText(row)}
    </CCButton>
  )
}
