// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../models'

import { InputSourceCell } from './InputSourceCell'
import { AskQuestionActionsCell } from './AskQuestionActionsCell'

type CellProps = {
  row: {
    original: KnowledgeItem
  }
}

export const ASK_QUESTION_COLUMNS = [
  {
    accessor: 'question',
    disableSortBy: true,
    Header: 'Question',
    Cell: ({ row }: CellProps) => (
      <div>
        <div className='k-table-question'>{row.original.question}</div>
        <div className='k-table-answer'>{row.original.answer}</div>
      </div>
    )
  },
  {
    accessor: 'input_source',
    disableSortBy: true,
    Header: 'Source',
    Cell: ({ row }: CellProps) => <InputSourceCell row={row.original} />
  },
  {
    accessor: 'actions',
    disableSortBy: true,
    Header: '',
    Cell: AskQuestionActionsCell
  }
]
