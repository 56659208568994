import React, { useEffect } from 'react'

import { useKnowledgeItems, useKnowledgeWebpageForm, useShop } from '../../../../../hooks'

import { WEBSITE_COLUMNS } from '../columns/WebsiteColumns'
import { KnowledgeItemsTableV2 } from '../KnowledgeItemsTableV2'
import { KnowledgeItemsSearchEmptyList } from '../KnowledgeItemsSearchEmptyList'
import { KnowledgeItemsEmptyListV2 } from '../KnowledgeItemsEmptyListV2'
import { KnowledgeBaseListSearch } from '../KnowledgeBaseListSearch'

export function WebsitePagesTable () {
  const [shop] = useShop()
  const {
    knowledgeWebsitePages,
    isLoadingList,
    pages,
    page,
    search,
    loadNextPage,
    loadKnowledgeWebsitePages,
    setSearch,
    orderValue,
    orderDirection,
    handleSortingClick
  } = useKnowledgeWebpageForm()
  const { knowledgeItems } = useKnowledgeItems()
  const isShowingEmptyList = knowledgeWebsitePages.length === 0 && !isLoadingList

  function handlePageClick (data: { selected: number }): void {
    if ((data.selected + 1) !== page) loadNextPage(data.selected + 1)
  }

  function renderContent (): any {
    if (isShowingEmptyList && search) return <KnowledgeItemsSearchEmptyList />
    if (isShowingEmptyList) return <KnowledgeItemsEmptyListV2 />

    return (
      <KnowledgeItemsTableV2
        rows={knowledgeWebsitePages}
        columns={WEBSITE_COLUMNS}
        isLoading={isLoadingList}
        pages={pages}
        page={page}
        onPageClick={handlePageClick}
        onSortingChange={handleSortingClick}
      />
    )
  }

  useEffect(() => {
    if (!shop.secure_id || search != null) return

    loadKnowledgeWebsitePages()
  }, [shop, orderValue, orderDirection, search])

  useEffect(() => {
    if (!shop.secure_id || search == null) return
    const interval = setTimeout(loadKnowledgeWebsitePages, 500)

    return () => clearTimeout(interval)
  }, [search])

  return (
    <>
      <KnowledgeBaseListSearch
        placeholder="Search from crawled web pages"
        search={search}
        knowledgeItems={knowledgeItems}
        onSearch={setSearch}
      />
      {renderContent()}
    </>
  )
}
