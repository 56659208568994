// @flow

import React from 'react'

import { KnowledgeWebsitePage } from '../../../../../models'
import { useKnowledgeWebpageForm, useModal } from '../../../../../hooks'

import { CCButton, TrboIcon } from '../../../../UI'

import { WebsitePageDeleteModal } from './modals/WebsitePageDeleteModal'

import './ActionsCell.scss'

type Props = {
  row: {
    original: KnowledgeWebsitePage
  }
}

export function WebsiteActionsCell ({ row }: Props) {
  const { isDeleting } = useKnowledgeWebpageForm()
  const [isDeleteModalOpen, , onDeleteRequestOpen, onDeleteRequestClose] = useModal()

  function handleURL () {
    window.open(row.original.url, '_blank')
  }

  return (
    <div className='k-table-additional-settings'>
      <CCButton
        tooltip="Open URL"
        onClick={handleURL}
      >
        <TrboIcon name="external-link" />
      </CCButton>
      {<CCButton
        onClick={onDeleteRequestOpen}
        disabled={ isDeleting(row.original) }
        tooltip="Delete entry"
      >
        <TrboIcon name="trash" />
      </CCButton>

      }
      {isDeleteModalOpen && (
        <WebsitePageDeleteModal
          row={row}
          onDeleteRequestClose={onDeleteRequestClose}
          dataSource="website"
        />
      )}
    </div>
  )
}
