// @flow

import React, { useEffect } from 'react'

import { useUser } from '../../../hooks'

import { KnowledgeBaseHeaderProgress } from '../../KnowledgeBase/pages/KnowledgeBaseList/KnowledgeBaseHeaderProgress'

import { CCNavbarUserProfile } from './CCNavbarUserProfile'
import { CCNavbarSettings } from './CCNavbarSettings'

import './CCNavbar.scss'

const HW_CONFIG_ACCOUNT = 'xb06bJ'

export function CCNavbar () {
  const { user } = useUser()

  const initHeadway = () => {
    if (!window.Headway) return

    window.Headway.init({
      selector: '.navbar-headway-notes-container',
      account: HW_CONFIG_ACCOUNT,
      trigger: '.headway-trigger',
      position: { x: 'left' }
    })
  }

  useEffect(() => {
    initHeadway()
  })

  return (
    <header className="main-header">
      <nav className="navbar navbar-static-top">
        <div className="navbar-custom-menu">
          <div className="navbar-training-container">
            <KnowledgeBaseHeaderProgress />
          </div>
          <ul className="nav navbar-nav">
            <li className="navbar-item navbar-headway-notes-container headway-trigger">
              <span className="headway-trigger">Release notes</span>
            </li>
            <li className="navbar-item">
              <a
                title="Help Center"
                href="https://guide.chatchamp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-question navbar-icon"/>Help center
              </a>
            </li>
            <CCNavbarSettings user={user}/>
            <CCNavbarUserProfile userName={user.name}/>
          </ul>
        </div>
      </nav>
    </header>
  )
}
